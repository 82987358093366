<template>
  <div class="FeedModeTabs">
    <Tabs
      :tabs="tabs"
      @tab="
        (tab) => {
          setQuery({ availableFor: tab.id });
          updateFeed();
        }
      "
    />
  </div>
</template>

<script>
export default {
  methods: {
    updateFeed: async function() {
      this.isLoading(true);
      const response = await this.$store.dispatch("getPosts", { query: Go.objectToQueryString(this.$route.query) });
      this.creator = response.data.profile;
      this.$store.state.creatorFeed = this.creator;
      this.isLoading(false);
    },
  },
  computed: {
    tabs: function() {
      return [
        {
          title: this.$locale["free"],
          selected: this.$route.query.availableFor === "public",
          event: "tab",
          id: "public",
        },
        {
          title: this.$locale["premium"],
          selected: this.$route.query.availableFor === "subscriber",
          event: "tab",
          id: "subscriber",
        },
        {
          title: this.$locale["plus"],
          selected: this.$route.query.availableFor === "purchase",
          event: "tab",
          id: "purchase",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.FeedModeTabs {
  padding: 0 $mpadding;
  .tabs {
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-shadow: $dshadow;
    border-radius: $mradius;
  }

  .tabs .tabs-tab {
    padding: $mpadding/1.3 $mpadding;
    background-color: #fff;
  }

  .tabs .tabs-tab.selected {
    font-family: $third_font_bold;
    background-color: $primary_color;
    color: #fff;
  }

  @include screen($tablet_width) {
    padding: 0 0;
    .tabs {
      border-radius: $mradius;
    }
  }
}
</style>
